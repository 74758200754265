.banner {
    overflow: hidden;
}

.banner div.contain1{
    transition: 0.5s;
    transform: translateY(-100%);
}

.banner div.contain2{
    transition: 0.5s;
    transform: translateX(100%);
}

.banner:hover div.contain1{
    transition: 0.5s;
    transform: translateY(0);
}

.banner:hover div.contain2{
    transition: 0.5s;
    transform: translateX(0);
}
.profile_container {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #ef673b;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ef673b;
    overflow: hidden;
}

.profile_image{
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.key{
    font-size: 20;
}

.value{
    font-size: 20;
    color: black;
}
  @import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Raleway&display=swap');


  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fe6303;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (min-width: 576px) {
    .more_files {
      max-width: 94% !important;
    }
  }

  .img_item_section {
    flex-wrap: wrap;
  }

  .img_item_section .grabbable {
    flex: initial !important;
    width: 25% !important;
    cursor: move !important;
    padding: 4PX;
  }

  .img_item_section .grabbable img {
    width: 100%;
  }

  .large_modal {
    min-width: 90%
  }

  .form_group_box {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .form_group_box .form-check {
    display: flex;
    align-items: center;
  }

  .form_group_box .form-check label {
    margin-bottom: 0;
  }

  .btn_cross_btn {
    position: absolute;
    top: -20px;
    right: -20px;
  }

  .table_action_btn {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-right: 6px;
  }

  .table_action_btn i {
    font-size: 12px !important;
  }

  .table_action_btn span {
    font-size: 12px !important;
  }

  .status_btn {
    padding: 2px 10px;
    font-size: 13px;
  }




  @media (min-width: 576px) {
    .modal_form.modal-dialog {
      margin-right: auto;
      margin-left: auto;
      max-width: 700px !important;
    }
  }


  .dropdown {
    position: relative;
    color: #333;
    cursor: default;
  }

  .dropdown .arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }

  .dropdown .arrow.open {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }

  .dropdown .selected-value input {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    width: 100%;
  }

  .dropdown .options {
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }

  .dropdown .options.open {
    display: block;
  }

  .dropdown .option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }

  .dropdown .option.selected,
  .dropdown .option:hover {
    background-color: #f2f9fc;
    color: #333;
  }

  .controls {
    display: none;
  }

  .image_contain:hover .controls {
    display: block;
  }

  .copy_button {
    background-color: white;
    border-radius: 2px;
    padding: 1px 5px;
    cursor: pointer;
  }

  /* .iSAVrt .rdt_TableCol:first-child {
  width: 40px !important;
  flex-grow: initial !important;
  padding: 0px 5px;
  min-width: 25px;
}

.dwPlXY .ifdffW:first-child {
  width: 40px !important;
  flex-grow: initial !important;
  padding: 0px 5px;
  min-width: 25px;
} */

  .image_placeholder {
    border: 1px dashed #2B3991;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
  }

  .searchWrapper .chip {
    background-color: #fe6303;
  }

  .optionContainer .highlight,
  .highlightOption,
  .highlightOption:hover {
    background: #fe6303 !important;
  }

  .optionContainer .option:hover {
    background: #fe6303 !important;
  }

  .toolbarClassName {
    width: 80%;
  }

  .editorClassName {
    min-height: 500px;
  }

  .lang-checks {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .lang-checks .check-group {
    margin-right: 10px;
  }

  .check-group {
    display: flex;
    align-items: center;
  }

  .check-group label {
    margin-bottom: 0;
  }

  .check-group input {
    margin-right: 5px;
  }

  .check-group input[type="checkbox"]:checked {
    background: #fe6303;
  }

  .lang-checks .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .lang-checks .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .lang-checks .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #fe6303;
  }

  /* On mouse-over, add a grey background color */
  .lang-checks .container:hover input~.checkmark {
    background-color: #fe6303;
  }

  /* When the checkbox is checked, add a blue background */
  .lang-checks .container input:checked~.checkmark {
    background-color: #fe6303;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .lang-checks .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .lang-checks .container input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .lang-checks .container .checkmark:after {
    left: 25%;
    top: 45%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg) translate(-50%, -50%);
  }

  .vertical-menu {
    padding-top: 10px;
  }

  .over {
    height: 85vh !important;
    overflow: hidden;
    overflow-y: scroll;
  }

  .over::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  .over::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  .over::-webkit-scrollbar-thumb {
    background: #fe6303;
  }

  /* Handle on hover */
  .over::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .circle_b {
    width: 300px;
    height: 300px;
  }

  .img-place-banner {
    border: 1px dashed #603813;
    height: 180px;
    margin-top: 10px;
    width: 250px;
  }

  .btn-add {
    border-radius: 50% !important;
    display: flex !important;
    font-size: 25px !important;
    height: 50px;
    width: 50px;
  }

  .action-btn,
  .btn-add {
    align-items: center;
    justify-content: center;
  }

  /* .action-btn {
    position: absolute;
    top: 0;
    bottom: 0;
  } */

  .action-btn {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .img-place-sqr-100 {
    border: 1px dashed #603813;
    height: 100px;
    width: 100px;
  }

  .btn-text-btn {
    position: relative;
    font-size: 14px;
    padding: 8px 30px;
    border-radius: 0.25rem;
  }

  .expend_detail {
    border: 2px solid rgba(0, 0, 0, 0.12);
    margin-top: 10px;
    font-size: 14px;
    padding: 5px;
  }


  @import url('https://fonts.googleapis.com/css2?family=Comic+Sans+MS:wght@400&display=swap');

  /* body {
    font-family: 'Comic Sans MS', Arial, sans-serif;
  } */


  .right-section-breadcrumb {
    display: flex;
    align-items: center;
  }

  .right-section-breadcrumb button {
    margin-right: 20px;
  }

  .card-title-d {
    color: #000;
    font-size: 18px;
  }

  .dashboard-card-box-section p {
    font-size: 12px;
  }

  .dashboard-card-box-section h4 {
    font-size: 16px;
  }

  .dashboard-card-box-section .card-section-dashboard .align-items-center {
    justify-content: space-between;
    height: 100%;
    gap: 0;
  }

  .dashboard-card-box-section .card {
    padding: 10px !important;
  }

  .dashboard-card-box-section .card-body {
    padding: 6px 10px !important;
  }

  .dashboard-card-box-section .card-body a {
    width: 100% !important;
    padding: 2px 8px !important;
    font-size: 12px !important;
  }

  .btn_bg {
    background-color: #343a40 !important;
    color: #fff !important;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    background-color: rgb(254, 99, 3) !important;
    color: #fff !important;
  }

  .dashboard-second-row .avatar-sm {
    width: 25px !important;
    height: 25px !important;
  }

  .dashboard-second-row {
    display: flex;
    gap: 25px;
    flex-direction: column;
  }

  .dashboard-second-row .card {
    height: 43% !important;
    margin: 0;
    padding: 0;

  }

  .card-section-dashboard .cat_icon {
    width: 25px;
    height: 25px;
    margin-bottom: 15px;
  }

  .card-section-dashboard .cat_icon img {
    width: 100%;
  }

  .list_icon_box {
    display: flex;
    align-items: center;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
    margin-bottom: 10px;
    gap: 10px;
  }

  .list_icon_box li {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .list_icon_box li .icon-name {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: start;
  }

  .list_icon_box li p {
    font-size: 12px;
    margin-bottom: 0;
  }

  .list_icon_box li h5 {
    font-size: 10px;
    margin-bottom: 0;
  }


  .list_icon_box li .avatar-xs {
    width: 20px !important;
    height: 20px !important;
    background-color: #000 !important;
    color: #fff !important;
  }

  .card-section-dashboard-box .avatar-sm {
    width: 22px !important;
    height: 22px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000 !important;
    margin-top: -1px;
  }

  .card-section-dashboard-box .avatar-sm i {
    color: #000 !important;
  }

  .card-section-dashboard .badge {
    border-radius: 14px !important;
    padding: 5px 10px !important;
  }

  .card-section-dashboard .badge.bg-success {
    background-color: transparent !important;
    border: 2px solid #45cb85 !important;
    color: #000 !important;
  }

  .card-section-dashboard .badge.bg-warning {
    background-color: transparent !important;
    border: 2px solid #eeb902 !important;
    color: #000 !important;
  }

  .card-section-dashboard .badge.bg-primary {
    background-color: transparent !important;
    border: 2px solid #3b5de7 !important;
    color: #000 !important;
  }

  .card-section-dashboard .badge.bg-info {
    background-color: transparent !important;
    border: 2px solid #0caadc !important;
    color: #000 !important;
  }

  .badge-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .user_img_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .vertical-menu .user-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fe6303;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .setting_box_content .mdi {
    font-size: 26px !important;
  }

  .setting_box_content .toggle-switch-inner:before,
  .setting_box_content .toggle-switch-inner:after {
    height: 28px !important;
    line-height: 29px !important;
  }

  .setting_box_content .toggle-switch-switch {
    width: 19px !important;
    right: 44px;
    height: 19px !important;
  }

  .card-button-box {
    position: relative;
  }

  .card-button-box button {
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    right: -20px;
    top: -24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .card-button-box button .mdi {
    font-size: 13px !important;
  }

  .btn-dark,
  .btn-secondary {
    color: #fff !important;
  }

  .filter-box-section {
    border: 1px solid #6b6c6d;
    padding: 10px;
    position: relative;
    border-radius: 0.25rem !important;
  }

  .filter-box-section .filter-reset-button {
    position: absolute;
    top: -1px;
    right: 1px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .filter-box-section .filter-reset-button button {
    background-color: #343a40 !important;
    color: #fff !important;
    border-radius: 0.25rem !important;
  }

  .lable-text label {
    font-size: 12px !important;
    font-weight: normal !important;
  }

  .rdt_TableCol,
  .rdt_TableCell {
    font-size: 12px !important;
  }

  .rdt_TableCell {
    font-size: 11px !important;
  }

  .card-subtitle {
    font-size: 12px !important;
  }

  button {
    font-size: 12px !important;
  }

  .rdt_Table button {
    padding: 2px 6px !important;
  }

  .rdt_Table button span {
    font-size: 12px !important;
  }

  .rdt_Table .hlVdDR img,
  .rdt_Table .bQFMWD img,
  .rdt_TableCell img {
    width: 25px !important;
    height: 25px !important;
  }

  .rdt_TableCell .table-icon {
    width: 15px !important;
    height: 15px !important;
    filter: brightness(0) invert(1);
  }

  .vertical-menu {
    z-index: 11;
  }

  .loader_spiner {
    color: #fe6303 !important;
  }

  .group_btn_box {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .group_btn_box button {

    background-color: #535c64 !important;
    border-color: #535c64 !important;
    margin-left: 5px;
    padding: 5px 8px;
    border: none;
    gap: 10px;
    color: #fff !important;
    width: fit-content;
  }

  .group_btn_box button:hover {
    background-color: rgb(254, 99, 3) !important;
    border-color: rgb(254, 99, 3) !important;
  }

  label {
    font-size: 12px !important;
  }

  .form-control {
    font-size: 12px !important;
  }

  .group_btn_box_2 {
    justify-content: start !important;
  }

  .group_btn_box_2 button:active,
  .group_btn_box_2 button:focus,
  .group_btn_box_2 button:focus-visible {
    background-color: #fe6303 !important;
  }

  .nav-pills {
    gap: 10px;
  }

  .nav-pills .nav-link {
    border: 1px solid #fe6303 !important;
  }

  .jvectormap-legend-cnt.jvectormap-legend-cnt-h,
  .jvectormap-legend-cnt.jvectormap-legend-cnt-v {
    display: none;
  }

  .jvectormap-container svg {
    height: 250px !important;
  }


  .logout-button-text {
    display: flex;
    align-items: center;
    color: #fff;
  }

  .logout-button-text a {
    color: #e9ecef;
    font-size: 24px;
  }

  body[data-layout="horizontal"] .right-section-breadcrumb {
    visibility: hidden;
  }


  body[data-layout="horizontal"] .hori_mr_0 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .topnav .navbar-nav .dropdown-item {
    font-size: 12px !important;
  }

  .inner-menu-page {
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: center;
    background-color: #000;
    padding: 0 8px;
  }

  .inner-menu-page li a {
    color: #fff !important;
    font-size: 12px;
    position: relative;
    padding: 8px 10px 8px 12px;
    height: auto;
    display: block;
  }

  .inner-menu-page li a::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #918b8b;
    width: 1px;
    height: 100%;
  }

  .inner-menu-page li:first-child a::after {
    display: none;
  }

  .simple-content-design-page {
    position: relative;
  }

  .simple-content-design-page h4 {
    font-size: 22px;
  }

  .simple-content-design-page p {
    font-size: 12px;
  }

  .breadcrumb-item,
  .breadcrumb-item>a {
    font-size: 12px !important;
  }

  .content-section-dashboard {
    position: relative;
  }

  .content-section-dashboard .inbox-list-item h5,
  .content-section-dashboard .inbox-list-item .text-truncate {
    font-size: 12px !important;
  }

  .content-section-dashboard .inbox-list-item .font-size-12.ms-auto {
    font-size: 11px !important;
  }

  .content-section-dashboard .inbox-list-item .align-self-center img {
    width: 30px !important;
    height: 30px !important;
  }

  .footer {
    font-size: 12px;
  }

  .btn_back {
    background-color: #fe6303;
    display: flex;
    padding: 5px 10px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }